@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover+label .box,
  &:focus+label .box,
  &:checked+label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover+label .boxSuccess,
  &:focus+label .boxSuccess,
  &:checked+label .boxSuccess {
    stroke: var(--colorSuccess);
  }

  /* disabled version */
  &:hover+label .boxDisabled,
  &:focus+label .boxDisabled,
  &:checked+label .boxDisabled {
    stroke: var(--colorGrey300);
  }

  /* Display the "check" when checked */
  &:checked+label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked+label .checkedSuccess {
    display: inline;
    stroke: var(--colorSuccess);
    stroke-width: 1px;
  }

  /* Display the disabled when checked */
  &:checked+label .checkedDisabled {
    display: inline;
    stroke: var(--colorGrey300);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus+label .text,
  &:hover+label .text,
  &:checked+label .text {
    color: var(--colorBlack);
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  display: none !important;
  height: 32px;
  margin-top: -1px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 24px;
  }
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}

.checkedSuccess {
  display: none;
  fill: var(--colorSuccess);
}

.checkedDisabled {
  display: none;
  fill: var(--colorGrey300);
}

.boxDisabled,
.boxSuccess,
.box {
  stroke: var(--colorGrey300);
}

.text {}

.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  padding: 0;
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

input[type='checkbox']+label {
  display: flex;
  cursor: pointer;
}

input[type='checkbox'] {
  /* display: none; */
}

input[type='checkbox']+label:before {
  content: "";
  background-position: center;
  border: 0.1em solid #ccc3c3;
  border-radius: 5px;
  display: inline-block;
  width: 26px;
  min-width: 26px;
  height: 26px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;

  @media (max-width: 767px) {
    min-width: 26px;
  }
}

input[type='checkbox']+label:active:before {
  transform: scale(0);
}

input[type='checkbox']:checked+label:before {
  background: var(--successColor);
  border-color: var(--matterColorNegative);
  color: white;
  background-image: url(../../assets/images/Checkmark.png);
  background-repeat: no-repeat;
  background-size: 26px;
  background-position: center;
}

input[type='checkbox']:disabled+label:before {
  transform: scale(1);
  border-color: var(--matterColorNegative);
}

input[type='checkbox']:checked:disabled+label:before {
  transform: scale(1);
  background-color: #f7c28f;
  border-color: #f7c28f;
}