/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderRound {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderRound:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 17px;
  left: 4px;
  bottom: 3px;
  background-color: rgb(255, 255, 255);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.sliderRound {
  background-color: #A28930;
}

input:focus+.sliderRound {
  box-shadow: 0 0 1px #A28930;
}

input:checked+.sliderRound:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliderRounds */
.sliderRound {
  border-radius: 34px;
}

.sliderRound:before {
  border-radius: 50%;
}