.root {}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 0px 15px;
  border-radius: 10px;
  height: 45px;
  padding-right: 40px;

  &>option {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}

.multiSelectBox {
  &>div {
    /* background: #FFFFFF; */
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* height: 40px; */
    color: #525961;
    border-radius: 4px;
    border: 1px solid #f2e9e0;
    background: #fcf8f2;

    &>div {
      padding: 1px 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

      &>div {
        color: #4A2A18;
        font-family: "Karla", sans-serif;
      }
    }

    & input {
      height: auto;

      &:focus {
        box-shadow: unset;
      }
    }
  }
}