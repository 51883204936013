.root {
    z-index: 9999999999999999;
}

.container {
    & img {
        width: 100%;
        height: 282px;
        object-fit: cover;
        transition: 0.3s;
        border: 1px solid var(--colorGrey100);
        border-radius: var(--borderRadiusMedium);
        overflow: hidden;
        cursor: pointer;
        &:hover {
            box-shadow: var(--boxShadowListingCard);
            transform: scale(1.02);
        }
        @media(max-width:600px) {
            height: 160px;
        }
    }

    & .noImageIcon {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 282px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        gap: 6px;
        background: #d3d3d394;

        & span {
            color: #4A4A4A;
        }

        & svg {
            & path {
                stroke: #4A4A4A;
            }
        }
        @media(max-width:600px) {
            height: 160px;
        }
    }

    & .price {
        font-family: 'Josefin Sans';
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.3047058880329132px;
        text-align: left;
        color: #A58930;
        margin-top: 12px;
        margin-bottom: 0;
    }

    & .title {
        color: var(--colorGrey700);
        color: #4a4a4a;
        font-family: Karla;
        font-size: 14px;
        font-style: normal;
        font-weight: var(--fontWeightBold);
        font-weight: 700;
        line-height: 20px;
        padding: 0;
        margin: 0;
        word-break: break-all;
    }

    & .storeName{
        color: var(--colorGrey700);
        font-weight: var(--fontWeightMedium);
        margin: 0;
        line-height: 16px;
        font-size: 13px;
        -webkit-font-smoothing: subpixel-antialiased;
    }
}