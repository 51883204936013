.root {
  fill: var(--marketplaceColor);
}

.containerWrapper {
  display: flex;
  align-items: center;
}

.tooltipContainer {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 4px;
}

.tooltipFlex {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  max-height: 80px;
  overflow-y: auto;

  @media(max-width:1024px) {
    flex-direction: column;
    gap: 5px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  & ::-webkit-scrollbar-track {
    background: #FCF8F2 !important;
    color: #FCF8F2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #f2e9e0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

 

  /* & svg {
    width: 32px;
    height: 32px;
  } */

  .headingText {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 800;
    line-height: 19.36px;
    text-align: left;
    color: #FCF8F2;
  }

  .subhedingText {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 800;
    line-height: 19.36px;
    text-align: left;
    color: #FCF8F2;
    padding-top: 8px;
  }
}

.tooltip {
  display: flex;
  align-items: center;
  visibility: hidden;
  background-color: #A4C3E6;
  color: #fff;
  text-align: left;
  padding: 14px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 140%;
  left: -76px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  width: 287px;
  z-index: 99999;

  @media(max-width:1024px) {
    left: 20px;
    width: 215px;
  }
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 17%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #A4C3E6 transparent transparent transparent;

  @media(max-width:1024px) {
    right: 50%;
  }
}

.tooltipContainer:hover .tooltip {
  visibility: visible;
  opacity: 1;
}